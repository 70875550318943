<template>
  <div>
    <div class="p-fluid formgrid grid">
      <div class="field col-12 md:col-12 lg:col-12">
        <pm-DataTable :value="alunos"   dataKey="id" :rowHover="true" filterDisplay="menu" v-model:filters="filters"
                responsiveLayout="scroll">
          <pm-Column field="aluno" sortable header="MATRICULA" style="font-size: 14px;vertical-align:middle !important">
            <template #body="{data}">
              <div>
                {{data.aluno.matricula}}
              </div>
            </template>
          </pm-Column>
          <pm-Column field="aluno" sortable header="NOME DO ALUNO" style="font-size: 14px;vertical-align:middle !important">
            <template #body="{data}">
              <div>
                {{data.aluno.nome}} {{data.aluno.sobrenome}}
              </div>
            </template>
          </pm-Column>
          <pm-Column field="aluno" header="PRESENTE(1ª chamada)" style="font-size: 14px;vertical-align:middle !important">
            <template #body="data">
              <div>
                <div class="form-check">
                  <p>Estava presente na 1ª chamada?</p>
                  <div class="col-md-12">
                    <input type="radio"  class="form-check-input" :name="'optradio1' + data.index"  :checked="data.data.presenca_1 == 1" 
                     @click="marcarPresencaFundamentalDois(1, '1', data.data)">  Sim<br>
                    <input type="radio"  class="form-check-input" :name="'optradio1' + data.index"  :checked="data.data.presenca_1 == 0"
                     @click="marcarPresencaFundamentalDois(1, '0', data.data)"> Não 
                  </div>
                </div>
              </div>
            </template>
          </pm-Column>
          <pm-Column field="aluno" header="PRESENTE(2ª chamada)" style="font-size: 14px;vertical-align:middle !important">
            <template #body="data">
              <div>
                <div class="form-check">
                  <p>Estava presente na 2ª chamada?</p>
                  <div class="col-md-12">
                    <input type="radio"  class="form-check-input" :name="'optradio2' + data.index"   :checked="data.data.presenca_2 == 1"
                     @click="marcarPresencaFundamentalDois(2, '1', data.data)">  Sim<br>
                    <input type="radio"  class="form-check-input" :name="'optradio2' + data.index"   :checked="data.data.presenca_2 == 0"
                     @click="marcarPresencaFundamentalDois(2, '0', data.data)"> Não 
                  </div>
                </div>
              </div>
            </template>
          </pm-Column>
        </pm-DataTable>
        <div class='col-md-12' style="margin-top:20px;">
          <button class="btn btn-primary" @click="EditarPresencaDaTurma(alunos)">Salvar Presença</button>
          <button class="btn btn-danger" @click="fecharEditarFundamentalUm(alunos)" style="margin-left: 10px;">Voltar</button>
        </div>  
      </div>
    </div> 

  </div>
</template>

<script>
export default {
  name: "editarFundamental1",
  props: {
    alunos: { type: Array, default: () => [] },
  },
  data () {
    return {
      isLoading:false,
    }
  },
  methods: {
    marcarPresencaFundamentalDois(chamada, valor, item){
      this.$emit('marcarPresencaFundamentalDois', chamada, valor, item);
    },
    EditarPresencaDaTurma(alunos){
      this.$emit('EditarPresencaDaTurma', alunos);
    },
    fecharEditarFundamentalUm() {
      this.$emit('fecharEditarFundamentalUm');
    }
  },
};
</script>

<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
  .cabecario-turma{
    background-color:#2fc982;
    color: #fff;
    margin-right: 10px;
    padding: 8px;
    border-radius: 5px;
    text-transform: uppercase;
  }
  @media(max-width:500px){
    .titulo-links-escolha {
      font-size: 24px;
    }
  }
</style>

<style scoped>
  .th_per1{
    background-color: #f8f9fa !important;
    color: #495057 !important;
    border-right: 1px solid #e9ecef !important;
    border-bottom: 2px solid #e9ecef !important;
    text-align: center;
    padding: 1rem 1rem;
    font-weight: 600;
  }  
  .th_pn_aluno{
    width:15%;
  }
  @media(max-width:720px){
    .th_pn_aluno{
      width:90%;
    }
  }

  @media(max-width:500px){
    .cabecario-turma{
      display: inline-grid;
      margin-bottom: 5px;
      font-size: 12px;
    }
  }

  .col-icon-univer{
    padding:0px;padding-top: 0px;padding-left: 10px;width: 4%;
  }
  @media(max-width:500px){
    .col-icon-univer{
      width: 15%;
    }
    .col-cturm{
      width: 80%;
    }
  }

  .escolha-nome-disciplina{
    font-size: 22px;margin-top: 10px;padding-bottom: 15px;
  }

  @media(max-width:500px){
     .escolha-nome-disciplina{
       font-size:16px;
     }
  }

  .semana{
    background-color: rgb(239 239 239);
    text-align: center;
  }
  .font-semana{
    font-size: 10px;
  }
  .dias{
    text-align: center;
  }
  .dia-marcado{
    background-color: #4182c5 !important;
    color: #fff;
  }

</style>

